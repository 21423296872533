import { createRoot } from 'react-dom/client';

import { App } from './App';

// This blocks needs to be removed when migration to React Router 7 occurs.
/* eslint-disable no-console */
const originalConsoleWarn = console.warn;
console.warn = (...args) => {
  // This prevents React Router console warning spam to opt-in early the new features flags in production
  if (process.env.NODE_ENV === 'production' && args[0].includes('React Router Future Flag Warning')) {
    return;
  }
  originalConsoleWarn(...args);
};
/* eslint-enable no-console */

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);
