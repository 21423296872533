import { createContext, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { type ComboBoxFieldOption, isEmpty, type PropsWithChildren } from '@blueprism/nextgen-platform-components';

import { useManagementRegionsApi } from './hooks';
import { getManagementRegionPrefix } from './utils';
import { type RegionContextState } from './types';

const defaultRegionState = {
  id: '',
  value: '',
};

const initialState = {
  managementRegionsOptions: [],
  managementRegionsLoading: false,
  selectedManagementRegion: defaultRegionState,
  selectManagementRegion: () => undefined,
  managementRegionPrefix: '',
};

export const RegionContext = createContext<RegionContextState>(initialState);

export function RegionContextProvider({ children }: PropsWithChildren) {
  const [selectedManagementRegion, selectManagementRegion] = useState<ComboBoxFieldOption | undefined>();
  const { managementRegionsOptions, isLoading } = useManagementRegionsApi(false);

  const location = useLocation();

  useEffect(() => {
    // `regionName` is hard-coded in the URL path in the third place
    // Example: https://domain-name.com/account/page-name/regionName
    const [, , , regionName] = location.pathname.split('/');

    const regionFromURL = managementRegionsOptions.find(({ id }) => id.toLowerCase() === regionName);

    const region = regionFromURL ?? selectedManagementRegion;

    if (!isEmpty(region)) {
      selectManagementRegion(region);

      return;
    }

    const [firstOperationalLocation] = managementRegionsOptions;

    selectManagementRegion(firstOperationalLocation);
  }, [location.pathname, managementRegionsOptions, selectedManagementRegion]);

  const managementRegionPrefix = getManagementRegionPrefix(selectedManagementRegion?.value);
  const managementRegionsLoading = isLoading || !selectedManagementRegion;

  return (
    <RegionContext.Provider
      value={{
        managementRegionsOptions,
        managementRegionsLoading,
        selectedManagementRegion,
        selectManagementRegion,
        managementRegionPrefix,
      }}
    >
      {children}
    </RegionContext.Provider>
  );
}
