import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';

import { useApiClient } from '@blueprism/nextgen-api';
import { ToastType, useToast } from '@blueprism/ui-pattern-toasts';

import { QueryKeys } from 'types/queries';
import { useAccountMultiRegionRouting } from 'hooks';
import { type AccountDataResponse, type AccountDetailsBody } from 'pages/Account/hooks/useTenantDetailsApi';
import { type AccountDetailsParams } from 'types';
import { getManagementRegionPrefix } from 'services/region';

import { type UpdateTenantInput } from '../../types';

import { UPDATE_TENANT, type UpdateTenantResponse, type UpdateTenantVariables } from './api';

export function useTenantDetailsEditApi(id: string) {
  const { requestWithPrefix } = useApiClient();
  const queryClient = useQueryClient();
  const { regionName } = useParams<AccountDetailsParams>();
  const { goToAccountDetails } = useAccountMultiRegionRouting(regionName);

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (variables: UpdateTenantVariables) =>
      requestWithPrefix<UpdateTenantResponse>(getManagementRegionPrefix(regionName), UPDATE_TENANT, variables),
  });

  const { formatMessage } = useIntl();

  const { triggerToast } = useToast();

  const tenantData = queryClient.getQueryData<AccountDataResponse>([QueryKeys.GetTenantDetails, id, regionName]);
  const tenant = tenantData?.tenant || ({} as AccountDetailsBody);

  async function handleSubmit(
    { name = '' }: Partial<UpdateTenantInput>,
    formikHelpers: FormikHelpers<UpdateTenantInput>,
  ) {
    try {
      formikHelpers.setSubmitting(true);

      await mutateAsync({
        input: {
          name,
          id: tenant.id,
          concurrencyStamp: tenant.concurrencyStamp,
        },
      });

      triggerToast({
        type: ToastType.SUCCESS,
        title: formatMessage({ id: 'account.notification.changesSaved' }),
        description: formatMessage(
          { id: 'account.notification.changesSavedEdit.message' },
          { oldName: tenant.name, newName: name },
        ),
      });
    } catch (error) {
      formikHelpers.resetForm();
    } finally {
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.GetTenantDetails, id, regionName] });

      goToAccountDetails(tenant.id);

      formikHelpers.setSubmitting(false);
    }
  }

  return { tenant, mutationLoading: isPending, handleSubmit };
}
