import { gql } from 'graphql-request';

export const GET_ACTIVATED_SKUS_BY_PRODUCT = gql`
  query ActivatedSkusByProductForTenant(
    $request: GetActivatedSkusByProductForTenantRequestInput!
    $order: [ActivatedSkuSortInput!]
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    activatedSkusByProductForTenant(
      request: $request
      order: $order
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      totalCount
      edges {
        node {
          id
          name
          from
          to
          skuStatus
          licenses {
            displayName
            isVisible
          }
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;
