import { PlatformApiProvider, EndpointType } from '@blueprism/nextgen-api';
import { type PropsWithChildren } from '@blueprism/nextgen-platform-components';

import { useConfig } from 'services/config';

import { API_NAMES, CLIENT_ID_KEY, CLIENT_ID } from './constants';

export function ApiProvider({ children }: PropsWithChildren) {
  const {
    configState: { ADMIN_API_GATEWAY },
    configReady,
  } = useConfig();

  if (!configReady) return null;

  return (
    <PlatformApiProvider
      clientIdKey={CLIENT_ID_KEY}
      clientId={CLIENT_ID}
      endpoints={{
        [API_NAMES.AdminApiGateway]: {
          url: ADMIN_API_GATEWAY,
          type: EndpointType.GraphQL,
          default: true,
        },
      }}
    >
      {children}
    </PlatformApiProvider>
  );
}
