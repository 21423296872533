import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { useApiClient } from '@blueprism/nextgen-api';

import { QueryKeys } from 'types/queries';
import { type AccountDetailsParams } from 'types';
import { getManagementRegionPrefix } from 'services/region';

import { type AccountAdminDataResponse, GET_TENANT_ADMIN_STATUS, type GetAccountAdminVariablesRequest } from './api';
import { type AccountAdminStatusDetails } from './types';

export function useAccountAdminStatusApi(tenantId: string) {
  const { requestWithPrefix } = useApiClient();
  const { regionName } = useParams<AccountDetailsParams>();

  const { data, isFetching } = useQuery({
    queryKey: [QueryKeys.GetAccountAdminStatus, tenantId, regionName],
    queryFn: () =>
      requestWithPrefix<AccountAdminDataResponse, GetAccountAdminVariablesRequest>(
        getManagementRegionPrefix(regionName),
        GET_TENANT_ADMIN_STATUS,
        {
          tenantId,
        },
      ),
    enabled: !!tenantId && !!regionName,
  });

  const tenantAdminDetails = data?.tenantAdminDetails || ({} as AccountAdminStatusDetails);

  return { isLoading: isFetching, tenantAdminDetails };
}
