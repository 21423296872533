import { type PropsWithChildren } from '@blueprism/nextgen-platform-components';
import { Cluster, Stack, Text } from '@blueprism/ui-core';

import { type ProductGroupProps } from './types';

export function ProductGroup({ children, title }: PropsWithChildren<ProductGroupProps>) {
  return (
    <Stack gap="small">
      <Text type="h5">{title}</Text>
      <Cluster gap="base">{children}</Cluster>
    </Stack>
  );
}
