import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { useFormatDate } from '@blueprism/nextgen-localisation';

import { AccountAdminStatus } from 'types/entities/AccountAdmin';
import { type AccountDetailsParams } from 'types';

import { useSendInvitationApi } from '../useSendInvitationApi';
import { useResendInvitationApi } from '../useResendInvitationApi';
import { type AccountAdminStatusDetails } from '../../../../hooks/useAccountAdminStatusApi';

export function useAccountAdminStatus({ status, dateInvitationLastSent }: AccountAdminStatusDetails) {
  const { accountId = '' } = useParams<AccountDetailsParams>();
  const { formatMessage } = useIntl();
  const { formatDateTimeShort } = useFormatDate();

  const dateLastInvitation = formatDateTimeShort(dateInvitationLastSent);
  const invitationDate = dateLastInvitation ? `(${dateLastInvitation})` : '';

  const { handleSendInvitation, isLoading: isSendInvitationLoading } = useSendInvitationApi(accountId);
  const { handleResendInvitation, isLoading: isResendInvitationLoading } = useResendInvitationApi(accountId);

  const showStatusButton = [
    AccountAdminStatus.INVITED,
    AccountAdminStatus.INVITATION_REQUESTED,
    AccountAdminStatus.INVITATION_RESENT,
    AccountAdminStatus.TENANT_ADMIN_CREATED,
    AccountAdminStatus.TENANT_ADMIN_CREATION_REQUESTED,
    AccountAdminStatus.INVITE_FAILED,
  ].includes(status);

  const defaultUserStatus = {
    showStatusButton,
    isLoading: false,
    handleStatusAction: () => {},
    userStatus: '',
    buttonLabel: '',
  };

  switch (status) {
    case AccountAdminStatus.TENANT_ADMIN_CREATION_REQUESTED:
    case AccountAdminStatus.TENANT_ADMIN_CREATED:
      return {
        ...defaultUserStatus,
        isLoading: isSendInvitationLoading,
        userStatus: formatMessage({ id: 'account.details.status.label.TENANT_ADMIN_CREATION_REQUESTED' }),
        buttonLabel: formatMessage({ id: 'account.details.status.button.sendInvitation' }),
        handleStatusAction: handleSendInvitation,
      };
    case AccountAdminStatus.ACTIVE:
      return {
        ...defaultUserStatus,
        userStatus: formatMessage({ id: 'account.details.status.label.ACTIVE' }),
      };
    case AccountAdminStatus.ACTIVATION_REQUESTED:
      return {
        ...defaultUserStatus,
        userStatus: formatMessage({ id: 'account.details.status.label.ACTIVATION_REQUESTED' }),
      };
    case AccountAdminStatus.INVITED:
    case AccountAdminStatus.INVITATION_REQUESTED:
    case AccountAdminStatus.INVITATION_RESENT:
      return {
        ...defaultUserStatus,
        isLoading: isResendInvitationLoading,
        userStatus: formatMessage({ id: 'account.details.status.label.INVITATION_SENT' }, { invitationDate }),
        buttonLabel: formatMessage({ id: 'account.details.status.button.resendInvitation' }),
        handleStatusAction: handleResendInvitation,
      };
    case AccountAdminStatus.INVITE_FAILED:
      return {
        ...defaultUserStatus,
        userStatus: formatMessage({ id: 'account.details.status.label.INVITE_FAILED' }),
        buttonLabel: formatMessage({ id: 'account.details.status.button.resendInvitation' }),
        handleStatusAction: handleResendInvitation,
      };
    case AccountAdminStatus.DEACTIVATED:
      return {
        ...defaultUserStatus,
        userStatus: formatMessage({ id: 'account.details.status.label.DEACTIVATED' }),
      };
    case AccountAdminStatus.DEACTIVATION_REQUESTED:
      return {
        ...defaultUserStatus,
        userStatus: formatMessage({ id: 'account.details.status.label.DEACTIVATION_REQUESTED' }),
      };
    case AccountAdminStatus.UNKNOWN:
      return { ...defaultUserStatus, userStatus: formatMessage({ id: 'account.details.status.label.UNKNOWN' }) };
    default:
      return defaultUserStatus;
  }
}
