import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { useApiClient } from '@blueprism/nextgen-api';

import { QueryKeys } from 'types/queries';
import { type AccountDetailsParams } from 'types';
import { getManagementRegionPrefix } from 'services/region';

import { GET_PRODUCTS, type GetProductsResponse, type GetProductsVariablesRequest } from './api';
import { type ProductGroups } from './api/types';

export function useProductsApi(tenantId: string) {
  const { requestWithPrefix } = useApiClient();
  const { regionName } = useParams<AccountDetailsParams>();
  const initialProducts = { activatedProducts: [], notActivatedProducts: [] };

  const { data = initialProducts, isFetching } = useQuery({
    queryKey: [QueryKeys.GetTenantProducts, tenantId, regionName],
    queryFn: () =>
      requestWithPrefix<GetProductsResponse, GetProductsVariablesRequest>(
        getManagementRegionPrefix(regionName),
        GET_PRODUCTS,
        {
          tenantId,
        },
      ),
    enabled: !!tenantId && !!regionName,
    select: (response: GetProductsResponse) => ({
      ...response?.products?.edges.reduce((acc: ProductGroups, { node }) => {
        const group = node?.isActivatedInTenant ? 'activatedProducts' : 'notActivatedProducts';
        acc[group].push(node);

        return acc;
      }, initialProducts),
    }),
    retry: 5,
  });

  return {
    isLoading: isFetching,
    activatedProducts: data.activatedProducts,
    notActivatedProducts: data.notActivatedProducts,
  };
}
