import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { useApiClient } from '@blueprism/nextgen-api';

import { QueryKeys } from 'types/queries';
import { type AccountDetailsParams } from 'types';
import { getManagementRegionPrefix } from 'services/region';

import { type AccountAdminDetailsFormData } from '../../types';

import { type AccountAdminDataResponse, GET_TENANT_ADMIN_DETAILS, type GetAccountAdminVariablesRequest } from './api';

export function useAccountAdminDetailsApi(tenantId: string) {
  const { requestWithPrefix } = useApiClient();
  const { regionName } = useParams<AccountDetailsParams>();

  const { data, isFetching } = useQuery({
    queryKey: [QueryKeys.GetAccountAdminDetails, tenantId, regionName],
    queryFn: () =>
      requestWithPrefix<AccountAdminDataResponse, GetAccountAdminVariablesRequest>(
        getManagementRegionPrefix(regionName),
        GET_TENANT_ADMIN_DETAILS,
        {
          tenantId,
        },
      ),
    enabled: !!tenantId && !!regionName,
  });

  const tenantAdminDetails = data?.tenantAdminDetails || ({} as AccountAdminDetailsFormData);

  return { isLoading: isFetching, tenantAdminDetails };
}
