import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Stack } from '@blueprism/ui-core';
import {
  NavContent,
  NavHeader,
  NavPrimaryItem,
  SideBar as BPSideBar,
  usePathname,
  NavFooter,
} from '@blueprism/ui-pattern-sidebar';
import { Out, People } from '@blueprism/ui-icons';

import { MockRolesLabels, useAuth } from 'services/auth';
import { AppRoutes } from 'routes';

export function SideBar() {
  const {
    authState: {
      userInfo: { firstName, lastName, roles },
    },
    clearAuthState,
  } = useAuth();

  const navigate = useNavigate();
  const { pathname } = usePathname();
  const { formatMessage } = useIntl();

  const rolesToShow = roles.map((role) => MockRolesLabels[role]).join(', ');

  function handleNavigation(_path: string) {
    navigate(_path);

    if (_path === AppRoutes.signOut) {
      clearAuthState();
      window.location.reload();
    }
  }

  return (
    <BPSideBar
      handleNavigation={handleNavigation}
      pathname={pathname}
      handleAriaLabel="navigation-sidebar-handle"
      skipLinkText={formatMessage({ id: 'header.skipLink.label' })}
    >
      <NavHeader
        message={rolesToShow}
        label={formatMessage({ id: 'navigation.sidebar.welcomeUserMessage' }, { name: firstName })}
        avatarName={formatMessage({ id: 'avatarName' }, { firstName, lastName })}
      />
      <NavContent>
        <NavPrimaryItem
          to={AppRoutes.Account}
          label={formatMessage({ id: 'navigation.accountList.label' })}
          icon={<People />}
        />
      </NavContent>
      <Stack padding="base">
        <NavPrimaryItem
          to={AppRoutes.signOut}
          label={formatMessage({ id: 'navigation.logOut.label' })}
          icon={<Out />}
        />
      </Stack>
      <NavFooter appName={formatMessage({ id: 'sideBar.appName' })} />
    </BPSideBar>
  );
}
