import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';

import { useApiClient } from '@blueprism/nextgen-api';
import { ToastType, useToast } from '@blueprism/ui-pattern-toasts';

import { QueryKeys } from 'types/queries';
import { type AccountDetailsParams } from 'types';
import { getManagementRegionPrefix } from 'services/region';

import { useProductActivationDrawerContext } from '../../context';
import { formatDateToString } from '../../utils';
import { type ActivateProductFormData } from '../../types';

import { ACTIVATE_SKU, type ActivateSkuResponse, type ActivateSkuVariables } from './api';
import { type MeterData } from './api/types';

export function useActivateSkuApi(tenantId: string, concurrencyStamp: ActivateSkuVariables['concurrencyStamp']) {
  const { requestWithPrefix } = useApiClient();
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { triggerToast } = useToast();
  const { regionName } = useParams<AccountDetailsParams>();

  const { closeDrawer, drawerState } = useProductActivationDrawerContext();

  const { mutateAsync } = useMutation({
    mutationFn: (variables: ActivateSkuVariables) => {
      return requestWithPrefix<ActivateSkuResponse>(getManagementRegionPrefix(regionName), ACTIVATE_SKU, variables);
    },
  });

  async function handleSubmit(
    { sku, startDate, endDate, ...meters }: Partial<ActivateProductFormData>,
    actions: FormikHelpers<ActivateProductFormData>,
  ) {
    if (!tenantId || !sku?.id || !drawerState?.id) return;

    const metersPayload: MeterData[] = Object.entries(meters).map(([key, value]) => ({
      identifier: key,
      limit: Number(value),
    }));

    try {
      await mutateAsync({
        tenantId,
        concurrencyStamp,
        skuCode: sku.id,
        meterData: metersPayload,
        productId: drawerState.id,
        from: formatDateToString(startDate),
        to: formatDateToString(endDate),
      });

      triggerToast({
        type: ToastType.SUCCESS,
        title: !drawerState?.name
          ? formatMessage({ id: 'account.productConfiguration.notification.skuAdded' })
          : formatMessage({ id: 'account.products.productsActivated' }),
        description: !drawerState?.name
          ? formatMessage({ id: 'account.productConfiguration.notification.skuAdded.message' })
          : formatMessage({ id: 'account.products.productsActivated.message' }, { displayName: drawerState.name }),
      });

      queryClient.invalidateQueries({ queryKey: [QueryKeys.GetTenantProducts, tenantId, regionName] });
      queryClient.invalidateQueries({ queryKey: [QueryKeys.GetActivatedSkusByProduct] });
      queryClient.invalidateQueries({ queryKey: [QueryKeys.GetActivatedProduct] });
    } catch (error) {
      triggerToast({
        type: ToastType.ALERT,
        title: !drawerState?.name
          ? formatMessage({ id: 'account.productConfiguration.notification.addingSkuFailed' })
          : formatMessage({ id: 'account.products.productsActivationFailed' }),
        description: !drawerState?.name
          ? formatMessage({ id: 'account.productConfiguration.notification.addingSkuFailed.message' })
          : formatMessage(
              { id: 'account.products.productsActivationFailed.message' },
              { displayName: drawerState.name },
            ),
      });
    } finally {
      actions.resetForm();
      closeDrawer();
    }
  }

  return { handleSubmit };
}
