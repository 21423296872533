import { type AccountCreationFormData } from '../types';

export const FIRST_NAME_MAX_LENGTH = 63;
export const LAST_NAME_MAX_LENGTH = 63;
export const TENANT_NAME_MAX_LENGTH = 63;
export const EMAIL_MAX_LENGTH = 254;
export const DOMAIN_NAME_SUBDOMAIN_MAX_LENGTH = 63;

export const initialValues: AccountCreationFormData = {
  adminFirstName: '',
  adminLastName: '',
  email: '',
  name: '',
  domainName: '',
  managementRegion: { id: '', value: '' },
};

export const DOMAIN_NAME_DISALLOWED_CHARACTERS_TO_VALIDATE = /^[A-Za-z0-9-.]+$/;

export const DOMAIN_NAME_DISALLOWED_CHARACTERS_TO_REPLACE = /[^A-Za-z0-9\s]/g;

export const DEFAULT_DOMAIN_NAME_MAX_SUBDOMAINS = 1;
