import { Outlet, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { useBreadcrumbs } from '@blueprism/nextgen-shared-hooks';
import { PageLayout } from '@blueprism/ui-layouts';
import { Breadcrumbs, Link, Placeholder, Stack, Text } from '@blueprism/ui-core';
import { ApiErrorBoundary } from '@blueprism/nextgen-api';

import { StyledTitleText } from 'components';
import { type AccountDetailsParams } from 'types';
import { AppRoutes } from 'routes';

import { useTenantNameApi } from '../hooks';

import { AccountNavigationRouterTabs } from './components/AccountNavigationRouterTabs';

export function AccountPageLayout() {
  const { formatMessage } = useIntl();
  const { accountId: id = '' } = useParams<AccountDetailsParams>();
  const { tenantName, isLoading } = useTenantNameApi(id);

  const breadCrumbLinks = [
    { href: AppRoutes.Account, title: formatMessage({ id: 'navigation.accountList.label' }) },
    { title: tenantName, dependOnLoading: true },
  ];

  const { breadcrumbs } = useBreadcrumbs(breadCrumbLinks, isLoading);

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs ariaLabel={formatMessage({ id: 'navigation.breadcrumbs.accountPage.ariaLabel' })}>
          {breadcrumbs.map(({ title, navigable, ...linkProps }) =>
            navigable ? (
              <Link {...linkProps} key={title}>
                {title}
              </Link>
            ) : (
              <Text key={title}>{title}</Text>
            ),
          )}
        </Breadcrumbs>
      }
      pageTitle={
        isLoading ? (
          <Placeholder width="16rem" height="3rem" />
        ) : (
          <StyledTitleText type="h4">{tenantName}</StyledTitleText>
        )
      }
      pageContent={
        <ApiErrorBoundary>
          <Stack gap="medium">
            <AccountNavigationRouterTabs />
            <Outlet />
          </Stack>
        </ApiErrorBoundary>
      }
    />
  );
}
