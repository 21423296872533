import { createContext, useState, useCallback, useEffect } from 'react';

import { authVerification } from '@blueprism/nextgen-api';

import { ServiceDownErrorPage } from 'pages';

import { CLIENT_ID_KEY, CLIENT_ID } from '../api/constants';

import { DefaultAuthState } from './constants';
import { type AuthStateContext, type AuthState, type AuthProviderProps } from './types';

export const AuthContext = createContext<AuthStateContext | undefined>(undefined);

export function AuthProvider({ children }: AuthProviderProps) {
  const [apiErrorReturned, setApiErrorReturned] = useState<boolean>(false);
  const [allowRender, setAllowRender] = useState<boolean>(false);
  const [authState, setAuthState] = useState<AuthState>(DefaultAuthState);

  const clearAuthState = useCallback(() => {
    setAllowRender(false);
    setAuthState(DefaultAuthState);
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      authVerification(CLIENT_ID_KEY, CLIENT_ID)
        .then((shouldRedirect) => {
          setAllowRender(shouldRedirect);
        })
        .catch((err) => {
          setApiErrorReturned(!!err);
        });
    } else {
      setAllowRender(true);
    }
  }, []);

  if (apiErrorReturned) {
    return <ServiceDownErrorPage />;
  }

  return (
    <AuthContext.Provider value={{ authState, setAuthState, clearAuthState }}>
      {children({ allowRender })}
    </AuthContext.Provider>
  );
}
