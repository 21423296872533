import { createContext, useState } from 'react';

import { type PropsWithChildren } from '@blueprism/nextgen-platform-components';

import { type ProductSku } from '../../hooks/useSkusApi/api/types';

import { type CurrentSkuContextState } from './types';

const initialState = {
  meters: [],
  licenses: [],
  currentSku: null,
  setCurrentSku: () => undefined,
};

export const CurrentSkuContext = createContext<CurrentSkuContextState>(initialState);

export function CurrentSkuProvider({ children }: PropsWithChildren) {
  const [currentSku, setCurrentSku] = useState<ProductSku | null>(null);

  const meters = currentSku?.meters || [];
  const licenses = currentSku?.licenses || [];

  return (
    <CurrentSkuContext.Provider
      value={{
        currentSku,
        setCurrentSku,
        meters,
        licenses,
      }}
    >
      {children}
    </CurrentSkuContext.Provider>
  );
}
