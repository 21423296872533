import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { useApiClient } from '@blueprism/nextgen-api';

import { QueryKeys } from 'types/queries';
import { TenantStatus } from 'types/entities/Tenant';
import { type AccountDetailsParams } from 'types';
import { getManagementRegionPrefix } from 'services/region';

import {
  type AccountDataResponse,
  type AccountDetailsBody,
  GET_TENANT_DETAILS,
  type GetAccountVariablesRequest,
} from './api';

export function useTenantDetailsApi(id: string) {
  const { requestWithPrefix } = useApiClient();
  const { regionName } = useParams<AccountDetailsParams>();
  const { data, isFetching } = useQuery({
    queryKey: [QueryKeys.GetTenantDetails, id, regionName],
    queryFn: () =>
      requestWithPrefix<AccountDataResponse, GetAccountVariablesRequest>(
        getManagementRegionPrefix(regionName),
        GET_TENANT_DETAILS,
        {
          id,
        },
      ),
    enabled: !!id && !!regionName,
  });

  const tenant = data?.tenant || ({} as AccountDetailsBody);

  const {
    isAuthenticationConfigured = false,
    hasProductConfigured = false,
    isDomainConfigured = false,
    status = TenantStatus.PENDING,
  } = tenant;

  const isAdminUserCreated = Boolean(tenant?.id);

  const onboarding = {
    isAdminUserCreated,
    isAuthenticationConfigured,
    hasProductConfigured,
    isDomainConfigured,
    isTenantConfigured: isAdminUserCreated && isAuthenticationConfigured && hasProductConfigured && isDomainConfigured,
    isTenantActivated: status === TenantStatus.ACTIVE,
  };

  return { isLoading: isFetching, tenant, onboarding };
}
