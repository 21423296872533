import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';

import { useApiClient } from '@blueprism/nextgen-api';
import { type PaginatedQueryVariables } from '@blueprism/nextgen-platform-table';

import { QueryKeys } from 'types/queries';
import { useRegionContext } from 'services/region';

import { GET_TENANTS, type GetTenantsResponse, type UseTenantListApiProps } from './api';

export function useTenantListApi({
  createPagingOptions,
  onCompleted,
  enabled = true,
  variables,
}: UseTenantListApiProps) {
  const { requestWithPrefix } = useApiClient();
  const { managementRegionPrefix } = useRegionContext();

  const { data, isSuccess, isLoading } = useQuery({
    queryKey: [QueryKeys.GetTenantList, variables, managementRegionPrefix],
    queryFn: () =>
      requestWithPrefix<GetTenantsResponse, PaginatedQueryVariables>(managementRegionPrefix, GET_TENANTS, variables),
    enabled,
  });

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    onCompleted?.();
  }, [isSuccess]);

  const listData = data?.tenants?.edges.map(({ node }) => node);
  const pageInfo = data?.tenants?.pageInfo;
  const totalCount = data?.tenants?.totalCount;

  const pagingOptions = createPagingOptions(pageInfo, totalCount);

  return { listData, isLoading, pagingOptions };
}
