import { useMutation } from '@tanstack/react-query';

import { useApiClient } from '@blueprism/nextgen-api';

import { getManagementRegionPrefix } from 'services/region';

import { parseDomainIndicatorState } from './utils/parseDomainIndicatorState';
import { GET_DOMAIN_NAME_VALIDATION, type ValidateDomainNameResponse, type ValidateDomainNameVariables } from './api';

export function useTenantDomainApi(managementLocation: string) {
  const { requestWithPrefix } = useApiClient();

  const { mutateAsync, data, isPending, isError, isIdle } = useMutation({
    mutationFn: (variables: ValidateDomainNameVariables) => {
      const managementRegionPrefix = getManagementRegionPrefix(managementLocation);

      return requestWithPrefix<ValidateDomainNameResponse>(
        managementRegionPrefix,
        GET_DOMAIN_NAME_VALIDATION,
        variables,
      );
    },
  });

  return {
    validatingDomain: isPending,
    getDomainIndicatorStatus: parseDomainIndicatorState({ data, isError, isIdle, isPending }),
    validateDomain: mutateAsync,
  };
}
