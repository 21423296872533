import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { useApiClient } from '@blueprism/nextgen-api';

import { QueryKeys } from 'types/queries';
import { type AccountDetailsParams } from 'types';
import { getManagementRegionPrefix } from 'services/region';

import { GET_SKUS, type GetSkusResponse, type GetSkusVariablesRequest } from './api';

export function useSkusApi(productId: string) {
  const { requestWithPrefix } = useApiClient();
  const { regionName } = useParams<AccountDetailsParams>();

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.GetProductSkus, productId, regionName],
    queryFn: () =>
      requestWithPrefix<GetSkusResponse, GetSkusVariablesRequest>(getManagementRegionPrefix(regionName), GET_SKUS, {
        productId,
      }),
    enabled: !!productId && !!regionName,
  });

  const skus =
    data?.skusByProduct?.edges.map(({ node }) => ({
      ...node,
      licenses: node.licenses.filter((license) => license.isVisible),
      meters: node.meters.filter((meter) => meter.isVisible),
    })) || [];

  return { isLoading, skus };
}
