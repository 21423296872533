import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { useApiClient } from '@blueprism/nextgen-api';

import { QueryKeys } from 'types/queries';
import { type AccountDetailsParams } from 'types';
import { getManagementRegionPrefix } from 'services/region';

import { GET_TENANT_NAME, type TenantNameDataResponse, type TenantNameVariablesRequest } from './api';

export function useTenantNameApi(id: string) {
  const { requestWithPrefix } = useApiClient();
  const { regionName } = useParams<AccountDetailsParams>();

  const { data, isFetching } = useQuery({
    queryKey: [QueryKeys.GetTenantName, id, regionName],
    queryFn: () =>
      requestWithPrefix<TenantNameDataResponse, TenantNameVariablesRequest>(
        getManagementRegionPrefix(regionName),
        GET_TENANT_NAME,
        {
          id,
        },
      ),
    enabled: !!id && !!regionName,
    throwOnError: false,
  });

  const tenantName = data?.tenant?.name || '';

  return { isLoading: isFetching, tenantName };
}
