import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { useApiClient } from '@blueprism/nextgen-api';

import { QueryKeys } from 'types/queries';
import { type AccountDetailsParams } from 'types';
import { getManagementRegionPrefix } from 'services/region';

import { GET_ACTIVATED_PRODUCT, type GetActivatedProductResponse, type GetActivatedProductVariables } from './api';

export function useActivatedProductApi(tenantId: string, productId: string) {
  const { requestWithPrefix } = useApiClient();
  const { regionName } = useParams<AccountDetailsParams>();

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.GetActivatedProduct, tenantId, productId, regionName],
    queryFn: () =>
      requestWithPrefix<GetActivatedProductResponse, GetActivatedProductVariables>(
        getManagementRegionPrefix(regionName),
        GET_ACTIVATED_PRODUCT,
        {
          tenantId,
          productId,
        },
      ),
    enabled: !!tenantId && !!productId && !!regionName,
    throwOnError: false,
  });

  const product = data?.activatedProduct;

  return {
    productName: product?.name || '',
    concurrencyStamp: product?.concurrencyStamp || null,
    isProductLoading: isLoading,
  };
}
