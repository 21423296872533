export type License = {
  displayName: string;
  identifier: string;
  name: string;
  operationLevel: string;
  isVisible: boolean;
  requiredEnvironmentType: string;
  requiredEnvironmentAttributes: {
    key: string;
    value: string;
  }[];
};

export type Meter = {
  limit: number;
  editableLimit: boolean;
  identifier: string;
  displayName: string;
  name: string;
  operationLevel: string;
  type: string;
  requiredEnvironmentType: string;
  requiredEnvironmentAttributes: {
    key: string;
    value: string;
  }[];
  isVisible: boolean;
};

export enum SkuStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  DEACTIVATED = 'DEACTIVATED',
}
export interface Sku {
  id: string;
  name: string;
  code: string;
  from: string;
  to: string;
  skuStatus: `${SkuStatus}`;
  licenses: License[];
  meters: Meter[];
}
