import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { useApiClient } from '@blueprism/nextgen-api';

import { QueryKeys } from 'types/queries';
import { type ProductConfigurationParams } from 'pages/ProductConfiguration/types';
import { type AccountDetailsParams } from 'types';
import { getManagementRegionPrefix } from 'services/region';

import {
  GET_ACTIVATED_SKU_BY_ID,
  type GetActivatedSkuByIdResponse,
  type GetActivatedSkuByIdVariablesRequest,
} from './api';
import { type ProductSku } from './api/types';

export function useActivatedSkuByIdApi(skuId: string) {
  const { requestWithPrefix } = useApiClient();

  const { regionName } = useParams<AccountDetailsParams>();
  const { accountId: tenantId = '', productId = '' } = useParams<ProductConfigurationParams>();

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.GetActivatedSkuById, tenantId, productId, skuId, regionName],
    queryFn: () =>
      requestWithPrefix<GetActivatedSkuByIdResponse, GetActivatedSkuByIdVariablesRequest>(
        getManagementRegionPrefix(regionName),
        GET_ACTIVATED_SKU_BY_ID,
        {
          tenantId,
          productId,
          skuId,
        },
      ),
    enabled: !!skuId && !!tenantId && !!productId && !!regionName,
    select: (response) => {
      if (!response?.activatedSkuById) return;

      const { licenses, meters, ...rest } = response.activatedSkuById;

      return {
        ...rest,
        licenses: licenses.filter((license) => license.isVisible),
        meters: meters.filter((meter) => meter.isVisible),
      };
    },
  });

  return { isLoading, activatedSku: data || ({} as ProductSku) };
}
