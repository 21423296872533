import { useFormatDate } from '@blueprism/nextgen-localisation';

import { type License } from 'types/entities';

export function formatLicenseDate({ startDate, endDate }: License<string>) {
  const { formatDate } = useFormatDate();

  return `${formatDate(startDate)} - ${formatDate(endDate)}`;
}

export function formatDateToString(date: Date | string | undefined) {
  if (typeof date === 'string') return date;

  const { formatDateTime } = useFormatDate();

  return formatDateTime(date?.setHours(0, 0, 0));
}
