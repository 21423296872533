import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useMutation } from '@tanstack/react-query';

import { ApiStatusCode, checkServerErrorStatus, useApiClient } from '@blueprism/nextgen-api';
import { ToastType, useToast } from '@blueprism/ui-pattern-toasts';

import { AppRoutes } from 'routes';
import { getManagementRegionPrefix } from 'services/region/utils';

import { type AccountCreationFormData } from '../../types';

import { type AddTenantResponse, type AddTenantVariables, CREATE_TENANT } from './api';
import { type UseAccountCreationPageApiProps } from './types';

export function useAccountCreationPageApi({
  isDomainVerified,
  setIsDomainNotVerified,
}: UseAccountCreationPageApiProps) {
  const { requestWithPrefix } = useApiClient();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { triggerToast } = useToast();

  function handleRedirectToProducts(accountId: string | undefined, selectedRegion: string) {
    navigate(AppRoutes.getProductsURL(selectedRegion, accountId));
  }

  function handleRedirectToGrid() {
    navigate(AppRoutes.Account);
  }

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (variables: AddTenantVariables) => {
      const managementRegionPrefix = getManagementRegionPrefix(variables.managementLocation);

      return requestWithPrefix<AddTenantResponse>(managementRegionPrefix, CREATE_TENANT, variables);
    },
    meta: {
      skipBadRequest: true,
    },
  });

  async function handleSubmit({
    adminFirstName = '',
    adminLastName = '',
    email = '',
    name = '',
    domainName = '',
    managementRegion = { id: '', value: '' },
  }: Partial<AccountCreationFormData>) {
    if (!isDomainVerified) {
      setIsDomainNotVerified(true);

      return;
    }

    try {
      const response = await mutateAsync({
        adminFirstName,
        adminLastName,
        email,
        name,
        domainName,
        managementLocation: managementRegion.id,
      });

      const accountData = response.createTenant;

      triggerToast({
        type: ToastType.SUCCESS,
        title: formatMessage({ id: 'account.notification.accountCreated' }),
        description: formatMessage(
          { id: 'account.notification.accountCreated.message' },
          { accountName: name, managementRegion: managementRegion.value },
        ),
      });

      handleRedirectToProducts(accountData?.entity?.id, managementRegion?.id);
    } catch (error) {
      if (checkServerErrorStatus(error, ApiStatusCode.BadRequest)) {
        triggerToast({
          type: ToastType.ALERT,
          title: formatMessage({ id: 'account.notification.accountCreationFailed' }),
          description: formatMessage(
            { id: 'account.notification.accountCreationFailed.message' },
            { accountName: name },
          ),
        });
      }
    }
  }

  return { isPending, handleSubmit, handleRedirectToGrid };
}
