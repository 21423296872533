import { useIntl } from 'react-intl';

import { TextFormField } from '@blueprism/ui-pattern-forms';

import { getMeterName } from 'components/ProductActivationFormDrawer/utils';
import { type ProductMeter } from 'components/ProductActivationFormDrawer/hooks/useSkusApi/api/types';

import { createMeterValidationSchema } from './validationSchema';

export function MeterTextField({ identifier, displayName, limit, editableLimit }: ProductMeter) {
  const { formatMessage } = useIntl();

  const transformedName = getMeterName(displayName, formatMessage);

  return (
    <TextFormField
      name={identifier}
      initialValue={`${limit}`}
      label={transformedName}
      helperText={formatMessage(
        { id: 'account.products.activateProducts.drawer.form.meterData.helperText' },
        { displayName: transformedName },
      )}
      validate={createMeterValidationSchema(formatMessage)}
      disabled={!editableLimit}
    />
  );
}
