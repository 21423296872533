import { useCallback } from 'react';

import { useIntl } from 'react-intl';
import { type FormikProps } from 'formik';

import { useDiscardChangesModal, type PropsWithChildren } from '@blueprism/nextgen-platform-components';
import { Drawer } from '@blueprism/ui-pattern-drawer';

import { useProductActivationDrawerContext } from '../../context';
import { type ActivateProductFormData } from '../../types';

export function ProductActivationDrawer({
  children,
  formikRef,
}: PropsWithChildren<{ formikRef: React.RefObject<FormikProps<ActivateProductFormData>> }>) {
  const { formatMessage } = useIntl();

  const { waitForAnswer } = useDiscardChangesModal();
  const { drawerState, closeDrawer } = useProductActivationDrawerContext();

  const onResetClick = useCallback(() => {
    formikRef.current?.resetForm();
  }, [formikRef.current?.resetForm]);

  async function handleWaitForAnswer() {
    if (formikRef.current?.dirty) {
      const confirmationDecision = await waitForAnswer({
        titleText: formatMessage({ id: 'form.modal.title.discardChanges' }),
        bodyText: formatMessage({ id: 'form.modal.question.changesNotSaved' }),
        cancelLabel: formatMessage({ id: 'buttons.cancel' }),
        acceptLabel: formatMessage({ id: 'buttons.continue' }),
      });

      return confirmationDecision;
    }

    return true;
  }

  async function handleClose() {
    const confirmationDecision = await handleWaitForAnswer();

    if (confirmationDecision) {
      onResetClick();
      closeDrawer();
    }
  }

  return (
    <Drawer
      toolbarAriaLabel="activate-product-drawer"
      title={drawerState?.header || formatMessage({ id: 'account.productConfiguration.AddSku.drawer.header' })}
      closeButtonLabel={formatMessage({ id: 'buttons.close' })}
      anchor="right"
      open={!!drawerState}
      onClose={handleClose}
      width={550}
    >
      {children}
    </Drawer>
  );
}
