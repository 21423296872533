import { type FormatMessage } from '@blueprism/nextgen-platform-components';

import { LicenseNames, MeterNames } from '../constants';

export const getLicenseName = (name: string, formatMessage: FormatMessage) => {
  if (name in LicenseNames) {
    const text = LicenseNames[name as keyof typeof LicenseNames];

    return formatMessage({ id: text });
  }

  return name;
};

export const getMeterName = (name: string, formatMessage: FormatMessage) => {
  if (name in MeterNames) {
    const text = MeterNames[name as keyof typeof MeterNames];

    return formatMessage({ id: text });
  }

  return name;
};
