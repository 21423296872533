import * as Yup from 'yup';
import { isSameDay, isAfter } from 'date-fns';
import { type PrimitiveType } from 'react-intl';

import { type CreateValidation } from '@blueprism/nextgen-platform-components';

import { type License } from 'types/entities';
import { type LanguageKeys } from 'translations';

export const createValidation: CreateValidation<License> = (formatMessage) => {
  const t = (text: LanguageKeys, values?: Record<string, PrimitiveType>) => formatMessage({ id: text }, values);

  return {
    startDateSchema: Yup.date()
      .required(t('date.picker.enter.valid.date'))
      .test(
        'startDate-is-after-endDate-and-not-same-day',
        t('date.picker.start.date.earlier.than.end'),
        function (value) {
          const { endDate } = this.parent;

          if (!value || !endDate) return true;

          return !isAfter(value, endDate) && !isSameDay(value, endDate);
        },
      ),
    endDateSchema: Yup.date().required(t('date.picker.enter.valid.date')),
  };
};
