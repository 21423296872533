import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { useApiClient } from '@blueprism/nextgen-api';

import { QueryKeys } from 'types/queries';
import { type AccountDetailsParams } from 'types';
import { getManagementRegionPrefix } from 'services/region';

import { DEFAULT_DOMAIN_NAME_MAX_SUBDOMAINS } from '../../constants';

import { GET_RUNNING_DOMAIN_QUERY, type GetRunningDomainResponse } from './api';

export function useRunningDomainApi() {
  const { requestWithPrefix } = useApiClient();
  const { regionName } = useParams<AccountDetailsParams>();

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.GetRunningDomainDetails, regionName],
    queryFn: () =>
      requestWithPrefix<GetRunningDomainResponse>(getManagementRegionPrefix(regionName), GET_RUNNING_DOMAIN_QUERY),
    enabled: !!regionName,
  });

  return {
    domain: data?.runningDomain?.domain || '',
    maxTenantSubdomainLevel: data?.runningDomain.maxTenantSubdomainLevel || DEFAULT_DOMAIN_NAME_MAX_SUBDOMAINS,
    isLoading,
  };
}
