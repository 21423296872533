import { useIntl } from 'react-intl';

import { FormContent, BasicFormLayout, TextFormField } from '@blueprism/ui-pattern-forms';
import { List, Stack } from '@blueprism/ui-core';

import { getLicenseName, getMeterName } from '../../utils';
import { type ProductLicensesAndMeters } from '../../hooks/useActivatedSkuById/api/types';

import { StyledListItem, StyledText } from './ProductActivationFormContent.styled';
import { FromToPicker } from './components';

export function ProductViewFormContent({ licenses = [], meters = [] }: ProductLicensesAndMeters) {
  const { formatMessage } = useIntl();

  const isCurrentSkusLicensesExists = licenses.length > 0;
  const isCurrentSkusMetersExists = meters.length > 0;

  return (
    <BasicFormLayout>
      <FormContent>
        <FromToPicker />
        <TextFormField
          name="status"
          label={formatMessage({ id: 'account.products.activateProducts.drawer.form.status.label' })}
          displayValueFormatter={(status) =>
            formatMessage({ id: `account.productConfiguration.table.status.${status}` })
          }
        />
        {isCurrentSkusLicensesExists && (
          <Stack gap="small">
            <StyledText>
              {formatMessage({ id: 'account.products.activateProducts.drawer.form.licenses.label' })}
            </StyledText>
            <List element="ul">
              {licenses.map(({ displayName }) => (
                <StyledListItem key={displayName}>{getLicenseName(displayName, formatMessage)}</StyledListItem>
              ))}
            </List>
          </Stack>
        )}
        {isCurrentSkusMetersExists && (
          <Stack gap="small">
            <StyledText>
              {formatMessage({ id: 'account.products.activateProducts.drawer.form.meters.label' })}
            </StyledText>
            <List element="ul">
              {meters.map(({ displayName, limit }) => (
                <StyledListItem
                  key={displayName}
                >{`${getMeterName(displayName, formatMessage)}: ${limit}`}</StyledListItem>
              ))}
            </List>
          </Stack>
        )}
      </FormContent>
    </BasicFormLayout>
  );
}
