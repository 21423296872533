import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { useApiClient } from '@blueprism/nextgen-api';

import { QueryKeys } from 'types/queries';
import { type AccountDetailsParams } from 'types';
import { getManagementRegionPrefix } from 'services/region';

import {
  GET_ACTIVATED_SKUS_BY_PRODUCT,
  type GetActivatedSkusByProductResponse,
  type RequestVariables,
  type UseActivatedSkusByProduct,
} from './api';

export function useActivatedSkusByProduct({ createPagingOptions, variables }: UseActivatedSkusByProduct) {
  const { requestWithPrefix } = useApiClient();
  const { regionName } = useParams<AccountDetailsParams>();
  const { tenantId, productId } = variables.request;

  const { data, isFetching } = useQuery({
    queryKey: [QueryKeys.GetActivatedSkusByProduct, variables, tenantId, productId, regionName],
    queryFn: () =>
      requestWithPrefix<GetActivatedSkusByProductResponse, RequestVariables>(
        getManagementRegionPrefix(regionName),
        GET_ACTIVATED_SKUS_BY_PRODUCT,
        variables,
      ),
    enabled: !!regionName && !!tenantId && !!productId,
  });

  const listData = data?.activatedSkusByProductForTenant?.edges.map(({ node }) => ({
    ...node,
    licenses: node.licenses.filter((license) => license.isVisible),
  }));
  const pageInfo = data?.activatedSkusByProductForTenant?.pageInfo;
  const totalCount = data?.activatedSkusByProductForTenant?.totalCount;

  const pagingOptions = createPagingOptions(pageInfo, totalCount);

  return { listData, isLoading: isFetching, pagingOptions };
}
